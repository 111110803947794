import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
    providedIn: 'root'
})

export class CryptographyService {
    private KEY = require('../security/file.json').data;

    public encryptData(data: any) {
        const IV: string = CryptoJS.lib.WordArray.random(128 / 8).toString().substring(0, 16);
        return IV + CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(typeof data !== 'string' ? JSON.stringify(data) : data), CryptoJS.enc.Utf8.parse(this.KEY),
            {
                keySize: 256 / 32,
                iv: CryptoJS.enc.Utf8.parse(IV),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
    }

    public decryptData(data: string) {
        if (data === "") return "";
        return CryptoJS.AES.decrypt(data.slice(16, data.length), CryptoJS.enc.Utf8.parse(this.KEY),
            {
                keySize: 256 / 32,
                iv: CryptoJS.enc.Utf8.parse(data.toString().substring(0, 16)),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
    }

}
