import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { SearchRulesResponse, AdvancedRuleSearchRequest, LookupItem } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

    generateExcel(result: SearchRulesResponse, searchRequest: AdvancedRuleSearchRequest, currentType: any) {
        //Excel Title, Header, Data
        const title = currentType.Name + ' Checks';
        let headers;
        if (currentType.Code === 'BA') {
            headers = ["Check Reference", "Check Completed", "Check Description"];
        } else if (currentType.Code === 'OM'){
            headers = ["Check Reference", "Type", "Check Completed", "Check Description"];
        }
        const header = headers;
        //Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Checks');
        //Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { size: 16, bold: true }
        worksheet.addRow([]);
        const d = new Date();
        worksheet.addRow(['Date : ' + d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()]);
        const standards: string = result.count + ' Standards to be checked for:';
        worksheet.addRow([standards]);
        let searchCriteria: string;
        if (currentType.Code === 'BA') {
            searchCriteria = searchRequest.documentType + ' International Requirements: ' + searchRequest.territories;
        } else if (currentType.Code === 'OM') {
            searchCriteria = searchRequest.typeOfInsurance + ',' + searchRequest.market + ',' + searchRequest.classOfBusinesses + ' International Requirements: ' + searchRequest.territories + ' Taxation: ' + searchRequest.taxCountries;
        }
        worksheet.addRow([searchCriteria]);
        //Blank Row 
        worksheet.addRow([]);
        //Add Header Row
        worksheet.addRow(header);

        //Add data
        result.items.forEach(item => {
            const row: string[] = [];
            row.push(item.reference);
            if (currentType.Code === 'OM') {
                row.push(item.classification);
            } 
            row.push(item.selected ? "Yes" : "No");
            row.push(item.description);
            worksheet.addRow(row);
        });

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, currentType.Name + ' Checks.xlsx');
        })
    }

}
