<div class="footer-container">
    <footer class="footer module black row">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="footer--copy-text module black pt-3">
                        <div class="row footer--row">
                            <ul class="footer--row--links">
                                <li class="footer--row--links--item ">
                                    <a class="hidelink" href="https://www.lloyds.com/common/privacy-notices" target="_blank">Privacy</a>
                                </li>
                                <li class="footer--row--links--item ">
                                    <a class="hidelink" href="https://www.lloyds.com/common/cookies-policy" target="_blank">Cookies</a>
                                </li>
                                <li class="footer--row--links--item ">
                                    <a class="hidelink" href="https://www.lloyds.com/common/terms-and-conditions" target="_blank">Terms &amp; Conditions</a>
                                </li>
                                <li class="footer--row--links--item ">
                                    <a class="hidelink" href="https://www.lloyds.com/modernslaverystatement" target="_blank">Modern Slavery Act</a>
                                </li>
                                <li class="footer--row--links--item last">
                                    <a class="hidelink" href="https://www.lloyds.com/help-and-glossary" target="_blank">Help</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer--social-wrap module  black pb-3">
                        <div class="row footer--row">
                            <ul class="footer--row--social">
                                <li class="footer--row--social--item">
                                    <a href="https://twitter.com/LloydsofLondon" class="btn btn-block btn-social btn-twitter" target="_blank">
                                        <span class="fa fa-twitter fa-lg"></span>
                                    </a>
                                </li>
                                <li class="footer--row--social--item">
                                    <a href="http://www.linkedin.com/company/lloyd%27s-of-london" class="btn btn-block btn-social btn-linkedin" target="_blank">
                                        <span class="fa fa-linkedin fa-lg"></span>
                                    </a>
                                </li>
                                <li class="footer--row--social--item">
                                    <a href="https://www.facebook.com/lloyds" class="btn btn-block btn-social btn-facebook" target="_blank">
                                        <span class="fa fa-facebook fa-lg"></span>
                                    </a>
                                </li>
                                <li class="footer--row--social--item">
                                    <a href="http://www.youtube.com/user/Lloydsinsurance" class="btn btn-block btn-social btn-youtube" target="_blank">
                                        <span class="fa fa-youtube fa-lg"></span>
                                    </a>
                                </li>
                            </ul>
                            <div class="copyright">
                                <p>Lloyd's Copyright 2020</p>
                                <p>
                                    Lloyd's and Corporation of Lloyd's are registered trademarks of the Society of Lloyd's<br>
                                    Lloyd's is authorised under the Financial Services and Markets Act 2000
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 lcc-banner">
                    <div class="footer-version-info ml-2">Client Version: <span *ngIf="version">{{version}}</span></div>
                    <div class="footer-version-info ml-2">Server Version: <span *ngIf="rbVersion">{{rbVersion}}</span></div>
                </div>
            </div>
        </div>
    </footer>
</div>
