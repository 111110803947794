import { Component } from '@angular/core';

@Component({
    selector: 'nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

    isExpanded = false;
    lloyds_logo = 'Scripts/libs/assets/logo_lloyds.png';

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

}
