import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'localDateTime' })

export class LocalDateTimePipe extends DatePipe implements PipeTransform {
    transform(value: Date, date?: any): any {

        if (!value) {
            return '';
        }

        if (value === null) {
            return null;
        }

        const dateFormat = date;
        const now = new Date(value);

        if (typeof now.getMonth === 'function') {
            const nowUtc = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
            if (dateFormat) {
                return super.transform(nowUtc, dateFormat);
            } else if (!dateFormat) {
                return super.transform(value);
            }
        } else {
            return value;
        }

    }
}
