<div *ngIf="browserIE11" 
     style="background-color: yellow;
        width: 100%;
        text-align: center;
        z-index:2147483647 !important;
">For better and more secure browsing of QA Tool, we recommend using another browser instead of Internet Explorer 11.</div>
<div *ngIf="url !== '/notes'">
    <nav-menu></nav-menu>
</div>
<router-outlet></router-outlet>
<div *ngIf="url !== '/notes'" class="footer">
    <app-footer></app-footer>
</div>
