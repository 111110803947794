<div class="wrapper">
    <div class="container mt-3 content">
        <h1 id="pageTitle">Change History</h1>
        <p *ngIf="!releaseHistoryResult || !ruleCommentsResult">Loading ...</p>

        <h3 class="header mt-3" *ngIf="ruleCommentsResult && ruleCommentsResult.items">Rule Updates</h3>

        <table *ngIf="ruleCommentsResult && ruleCommentsResult.items" mat-table [dataSource]="ruleCommentsResult.items" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of ruleCommentsColumnsToDisplay">
                <th mat-header-cell *matHeaderCellDef>
                    <ng-container *ngIf="column === 'businessArea'">Business Area </ng-container>
                    <ng-container *ngIf="column !== 'businessArea'">{{column | titlecase}} </ng-container>
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="column === 'updated'">
                        {{element.dateUpdated | localDateTime:'shortTime'}}
                        <mat-icon *ngIf="expandedRuleCommentsElement !== element" aria-hidden="false" aria-label="Expand more">expand_more</mat-icon>
                        <mat-icon *ngIf="expandedRuleCommentsElement === element" aria-hidden="false" aria-label="Expand less">expand_less</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="column !== 'updated'">{{element[column]}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="ruleCommentsColumnsToDisplay.length">
                    <div class="maintenance-element-detail" [@detailExpand]="element == expandedRuleCommentsElement ? 'expanded' : 'collapsed'">
                        <div class="maintenance-element-description">
                            <div>
                                <span class="maintenance-description-text" *ngIf="element.text" [innerHtml]="element.text" style="display:block;"></span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="ruleCommentsColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: ruleCommentsColumnsToDisplay;"
                class="maintenance-element-row"
                [class.maintenance-expanded-row]="expandedRuleCommentsElement === element"
                (click)="expandedRuleCommentsElement = expandedRuleCommentsElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="maintenance-detail-row"></tr>
        </table>
        <div class="center mt-3">
            <button mat-raised-button color="primary" *ngIf="ruleCommentsResult && ruleCommentsResult.items.length < ruleCommentsResult.totalRows" (click)="loadMoreRuleCommentsResults();">
                Show More Items
                <mat-icon *ngIf="ruleCommentsSearchInProgress">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            <button mat-raised-button disabled *ngIf="ruleCommentsResult && ruleCommentsResult.items.length == ruleCommentsResult.totalRows">No More Items to Show</button>
        </div>

        <h3 class="header mt-3" *ngIf="releaseHistoryResult && releaseHistoryResult.items">Product Updates</h3>

        <table *ngIf="releaseHistoryResult && releaseHistoryResult.items" mat-table [dataSource]="releaseHistoryResult.items" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of releaseHistoryColumnsToDisplay">
                <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="column === 'updated'">
                        {{element.dateUpdated | localDateTime:'shortTime'}}
                        <mat-icon *ngIf="expandedReleaseHistoryElement !== element" aria-hidden="false" aria-label="Expand more">expand_more</mat-icon>
                        <mat-icon *ngIf="expandedReleaseHistoryElement === element" aria-hidden="false" aria-label="Expand less">expand_less</mat-icon>

                    </ng-container>
                    <ng-container *ngIf="column === 'version'">{{element[column]}}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="releaseHistoryColumnsToDisplay.length">
                    <div class="maintenance-element-detail" [@detailExpand]="element == expandedReleaseHistoryElement ? 'expanded' : 'collapsed'">
                        <div class="maintenance-element-description">
                            <div>
                                <span class="maintenance-description-text" *ngIf="element.detail" [innerHtml]="element.detail" style="display:block;"></span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="releaseHistoryColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: releaseHistoryColumnsToDisplay;"
                class="maintenance-element-row"
                [class.maintenance-expanded-row]="expandedReleaseHistoryElement === element"
                (click)="expandedReleaseHistoryElement = expandedReleaseHistoryElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="maintenance-detail-row"></tr>
        </table>
        <div class="center mt-3">
            <button mat-raised-button color="primary" *ngIf="releaseHistoryResult && releaseHistoryResult.items.length < releaseHistoryResult.totalRows" (click)="loadMoreReleaseHistoryResults();">
                Show More Items
                <mat-icon *ngIf="releaseHistorySearchInProgress">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            <button mat-raised-button disabled *ngIf="releaseHistoryResult && releaseHistoryResult.items.length == releaseHistoryResult.totalRows">No More Items to Show</button>
        </div>
    </div>
</div>


