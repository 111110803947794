<div class="wrapper-home">
    <!--picture with headings-->
    <div class="homeimg">
        <div class="container">
            <h1>Quality Assurance (QA) Tools</h1>
            <h2>Periodical updates on contract quality requirements for open market and binding authority contracts</h2>
        </div>
    </div>
    <!--business areas-->
    <div class="container mt-3">
        <p *ngIf="!result">Loading ...</p>
        <div class="row" *ngIf="result && result.LookupData && result.LookupData.BusinessArea">
            <div class="col-lg-6 mb-2" *ngFor="let item of result.LookupData.BusinessArea.Items.Item; let i = index;let odd=odd">
                <div [ngClass]="odd? '':'vertical-border'" *ngIf="item.Name !== '(Not Selected)'">
                    <h2><a [routerLink]="['/search',item.Code]" class="black-font">{{item.Name}} QA Tool</a></h2>
                    <p><a [routerLink]="['/search',item.Code]" class="black-font">{{item.Description}}</a></p>
                    <p><a [routerLink]="['/search',item.Code]"><i class="material-icons size-inherit">arrow_forward</i> <span class="black-font">Find out more</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <!--help-->
    <div class="home-help-panel">
        <div class="container">
            <div class="row pt-3 pb-3">
                <div class="col-lg-6 header">
                    New to QA Tools?
                </div>
                <div class="col-lg-6 body">
                    <a [routerLink]="['/faq']" class="white-font">Here you'll find some useful information about how the tools work and answers to the most common questions.</a>
                    <p>
                        <a [routerLink]="['/faq']" class="yellow-font">
                            <i class="material-icons size-inherit">arrow_forward</i>
                            <span>
                                Read more
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--maintenance-->
    <div class="home-maintenance-panel">
        <div class="container" *ngIf="showMaintenance === true && maintenanceResult && maintenanceResult.items[0]">
            <div class="row pt-3 pb-3">
                <div class="col-lg-6 header">
                    Upcoming Maintenance
                </div>
                <div class="col-lg-6 body trimmed">
                    <a href="/maintenance" class="white-font">
                        {{maintenanceResult.items[0].start | date}}<br>
                        {{maintenanceResult.items[0].releaseHistory.detail}}
                    </a>
                    <p>
                        <a [routerLink]="['/maintenance']" class="yellow-font">
                            <i class="material-icons size-inherit">arrow_forward</i>
                            <span>
                                Read more
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!--Contact details-->
    <div class="container">
        <div class="container" *ngIf="contactDetails">
            <div class="row home-contactinfo pt-3">
                <div class="col-lg-12 home-contactinfo-1line">
                    For technology issues and general queries please contact:

                </div>
                <div class="col-lg-8">
                    <div class="col-lg-12 home-contactinfo-2line mt-3" *ngIf="contactDetails && contactDetails.name">
                        {{contactDetails.name}}
                    </div>
                    <div class="col-lg-12 mt-3" *ngIf="contactDetails && contactDetails.email">
                        <a href="mailto:{{contactDetails.email}}" class="home-contactinfo">{{contactDetails.email}}</a>
                    </div>
                    <div *ngIf="contactDetails && contactDetails.tel" class="col-lg-12 mt-1">
                        <a href="tel: {{contactDetails.tel}}" class="home-contactinfo">{{contactDetails.tel}}</a>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="lcc-banner mb-1">
                    <a href="https://www.imagefast.co.uk/lcc" target="_blank"><img _ngcontent-uut-c4="" src="Scripts/libs/assets/LCC.png"></a></div>
                </div>

            </div>
        </div>
    </div>
</div>
