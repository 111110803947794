import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiDataService } from '../services/api-data.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

import 'jspdf-autotable';

import { CookieService } from '../services/cookie.service';
import { LookupItem, AdvancedRuleSearchRequest, SearchRulesResponse, Filter, Item, GetFormDefinitionRequest, GetFormDefinitionResponse, Enum, ContactDetailsResponse } from '../models/models';
import { ExcelService } from '../services/excel.service';
import { PdfService } from '../services/pdf.service';
import { CryptographyService } from '../services/cryptography.service';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
    lcc_logo = 'Scripts/libs/assets/LCC.png';
    panelOpenState = false;
    panelExpanded = false;
    destroy$: Subject<boolean> = new Subject<boolean>();

    selectedClassesOfBusiness = new FormControl('', Validators.required);
    selectedClassesOfBusinessFieldIncluded: boolean;
    selectedTerritories = new FormControl('', Validators.required);
    selectedTerritoriesFieldIncluded: boolean;
    selectedTaxCountries = new FormControl('', Validators.required);
    selectedTaxCountriesFieldIncluded: boolean;
    selectedDocumentType = new FormControl('', Validators.required);
    selectedDocumentTypeFieldIncluded: boolean;
    selectedInsuranceTypes = new FormControl('', Validators.required);
    selectedInsuranceTypesFieldIncluded: boolean;
    selectedMarket = new FormControl('', Validators.required);
    selectedMarketFieldIncluded: boolean;

    selectedTaxCountriesList: LookupItem[];
    selectedTaxCountriesHaveLinks: boolean;
    selectedTerritoriesList: LookupItem[];
    selectedTerritoriesHaveLinks: boolean
    result: any;
    viewType: string[] = ['Summary View', 'Detail View'];
    chosenviewType: string = 'Detail';
    filteredCOBList: LookupItem[];
    filteredDocumentTypeList: LookupItem[];
    classificationFilter: Filter[] = []
    // eslint-disable-next-line
    groupedResults: any = {};
    groupedResultKeys: string[] = [];
    type: string = null;
    currentType: LookupItem;
    searchRequest: AdvancedRuleSearchRequest;
    rulesResult: SearchRulesResponse;
    filteredRuleResult: SearchRulesResponse;
    resultsHeaderDescription: string;
    searchInProgress: boolean;
    searchComplete: boolean;
    exportToExcelInProgress: boolean;
    exportToPdfInProgress: boolean;
    OM_logo = 'Scripts/libs/assets/qa-openmarket.gif';
    BA_logo = 'Scripts/libs/assets/qa-bindingauthority.gif';
    formDefinitionResponse: GetFormDefinitionResponse;
    selectFormControl = new FormControl('', Validators.required);
    disableButton: boolean;
    contactDetails: ContactDetailsResponse;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cookieService: CookieService,
        private apiDataService: ApiDataService,
        private excelService: ExcelService,
        private pdfService: PdfService,
        private cryptographyService: CryptographyService) {

        // values initiation
        this.disableButton = false;

        this.searchRequest = {
            businessArea: null,
            classification: null,
            classOfBusinesses: [],
            documentType: null,
            documentTypes:[],
            marketString: null,
            market: [],
            section: null,
            taxCountries: [],
            territories: [],
            typeOfInsuranceString: null,
            typeOfInsurance: [],
            enabled: true
        };
        this.selectedClassesOfBusinessFieldIncluded = false;
        this.selectedTerritoriesFieldIncluded = false;
        this.selectedTaxCountriesFieldIncluded = false;
        this.selectedDocumentTypeFieldIncluded = false;
        this.selectedInsuranceTypesFieldIncluded = false;
        this.selectedMarketFieldIncluded = false;
        // get business area type from router parameter
        this.route.params.subscribe((params: Params) => {
            this.type = params['type'].toUpperCase() || null;
            this.searchRequest.businessArea = this.type;
            if (this.type.toUpperCase() === 'OM') {
                this.resultsHeaderDescription = 'The requirements applicable to your selection are shown below. Underwriters can identify the risk management checks using the "Filter by" options below.';
            } else if (this.type.toUpperCase() === 'BA') {
                this.resultsHeaderDescription = 'The requirements applicable to your selection are shown below. Underwriters can view the checks at a summary or detail level using the "Filter By" options below (see guidance notes for further details).';
            }
            
            this.getFormDefinition();

        });
        this.apiDataService.getContactDetails()
            .subscribe(result => {
                if (result) {
                    this.contactDetails = result.body;
                }
            });
    }

    getEnumValue(text: string, enumList: Enum[]): number {
        return enumList.find(e => e.text === text).value;
    }

    getFormDefinition() {
        const request: GetFormDefinitionRequest = {
            formName: 'FORM_QATOOL_SEARCH_' + this.type.toUpperCase(),
            formId: null
        }
        this.apiDataService.getFormDefinition(request)
            .subscribe(data => {
                this.formDefinitionResponse = data.body;
                this.selectedInsuranceTypesFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_TYPE_OF_INSURANCE') >= 0 ? true : false;
                this.selectedClassesOfBusinessFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_CLASS_OF_BUSINESS') >= 0 ? true : false;
                this.selectedMarketFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_MARKET') >= 0 ? true : false;
                this.selectedDocumentTypeFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_DOCUMENT_TYPE') >= 0 ? true : false;
                this.selectedTaxCountriesFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_TAX_COUNTRY') >= 0 ? true : false;
                this.selectedTerritoriesFieldIncluded = this.formDefinitionResponse.item.searchFields.findIndex(el => el.internalName === 'FIELD_TERRITORY') >= 0 ? true : false;

                if (this.formDefinitionResponse.item.resultsDescription) {
                    this.resultsHeaderDescription = this.formDefinitionResponse.item.resultsDescription;
                }
            });
    }

    resetSearch() {
        this.searchRequest = {
            businessArea: null,
            classification: null,
            classOfBusinesses: [],
            documentType: null,
            documentTypes:[],
            market: [],
            marketString: null,
            section: null,
            taxCountries: [],
            territories: [],
            typeOfInsurance: [],
            typeOfInsuranceString: null,
            enabled: true,
        };
        this.selectedClassesOfBusiness.reset([]);
        this.selectedDocumentType.reset();
        this.selectedInsuranceTypes.reset();
        this.selectedMarket.reset();
        this.selectedTaxCountries.reset([]);
        this.selectedTerritories.reset([]);

    }
    amendSelection() {
        this.searchComplete = false;
        this.classificationFilter = [];
        this.filteredRuleResult = Object.assign({}, this.rulesResult);
    }

    ngOnInit() {
        this.searchInProgress = false;
        this.searchComplete = false;
        this.exportToExcelInProgress = false;
        this.exportToPdfInProgress = false;

        this.loadLookupData();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Unsubscribe from the subject
        this.destroy$.unsubscribe();
    }

    public loadLookupData() {

        let lookupDataCollection = this.apiDataService.lookups.value;

        if (typeof lookupDataCollection === 'undefined' || lookupDataCollection === null || Object.keys(lookupDataCollection).length === 0) {
            let lookupData = sessionStorage.getItem('lookup');

            if (lookupData && lookupData !== null && lookupData !== "") {
                lookupData = this.cryptographyService.decryptData(lookupData);

                if (lookupData && lookupData !== "") {
                    lookupDataCollection = JSON.parse(decodeURIComponent(lookupData));

                }
            }
        }

        if (typeof lookupDataCollection !== 'undefined' && lookupDataCollection !== null && Object.keys(lookupDataCollection).length !== 0) {

            this.result = { LookupData: lookupDataCollection, token: null };
            this.filterNotSelected();
            this.currentType = this.result.LookupData.BusinessArea.Items.Item.find(item => item.Code.toUpperCase() === this.type.toUpperCase());

            this.filteredCOBList = [];
            this.filteredDocumentTypeList = this.result.LookupData.BusinessAreaDocumentType.Items.Item.filter(item => item.BusinessAreaCode === this.type.toUpperCase() && item.DocumentTypeName !=="(Not Selected)");
        } else {

            this.apiDataService.getLookupData()
                .subscribe(result => {
                    this.result = JSON.parse(result.body.xml);
                    this.filterNotSelected();
                    if (this.result.LookupData && this.type) {
                        this.currentType = this.result.LookupData.BusinessArea.Items.Item.find(item => item.Code.toUpperCase() === this.type.toUpperCase());
                        this.filteredCOBList = [];
                        this.filteredDocumentTypeList = this.result.LookupData.BusinessAreaDocumentType.Items.Item.filter(item => item.BusinessAreaCode === this.type.toUpperCase() && item.DocumentTypeName !== "(Not Selected)");
                        //have to use session storage instead of cookies as data are too big for cookies
                        sessionStorage.setItem('lookup', this.cryptographyService.encryptData(JSON.stringify(this.result.LookupData)));
                    }
                })
        }

    }

    filterNotSelected() {
        this.result.LookupData.TypeOfInsurance.Items.Item = this.result.LookupData.TypeOfInsurance.Items.Item.filter(item => item.Name !== "(Not Selected)");
        this.result.LookupData.Market.Items.Item = this.result.LookupData.Market.Items.Item.filter(item => item.Name !== "(Not Selected)");
        this.result.LookupData.ClassOfBusiness.Items.Item = this.result.LookupData.ClassOfBusiness.Items.Item.filter(item => item.Name !== "(Not Selected)");
        this.result.LookupData.TaxCountry.Items.Item = this.result.LookupData.TaxCountry.Items.Item.filter(item => item.Name !== "(Not Selected)");
        this.result.LookupData.Territory.Items.Item = this.result.LookupData.Territory.Items.Item.filter(item => item.Name !== "(Not Selected)");
    }



    public searchResults() {
        this.disableButton = true;
        this.searchRequest.businessArea = this.currentType.Code;
        this.searchRequest.market = [];
        this.searchRequest.market.push(this.searchRequest.marketString);
        this.searchRequest.typeOfInsurance = [];
        this.searchRequest.typeOfInsurance.push(this.searchRequest.typeOfInsuranceString);
        this.searchInProgress = true;
        this.groupedResultKeys = [];
        this.groupedResults = {};
        this.apiDataService.searchRules(this.searchRequest)
            .subscribe(data => {
                this.rulesResult = data.body;
                if (this.type.toUpperCase() === 'BA') {
                    this.applyBAFilter('Detail View');
                } else {
                    this.filteredRuleResult = Object.assign({}, this.rulesResult);

                    const groupBySection = {};

                    this.rulesResult.items.forEach(function (a) {
                        groupBySection[a.section] = groupBySection[a.section] || [];
                        groupBySection[a.section].push({ reference: a.reference, classification: a.classification, description: a.description, selected: a.selected, order: a.order });
                    });

                    this.groupedResults = groupBySection;
                    Object.keys(this.groupedResults).forEach(key => { this.groupedResultKeys.push(key) });
                    //sort grouped results by order
                    this.groupedResultKeys.forEach(k => this.groupedResults[k] = this.groupedResults[k].sort((a, b) => (a.reference > b.reference) ? 1 : -1));

                }


                this.searchInProgress = false;

                this.result.LookupData.Classification.Items.Item.forEach(c => {
                    if (this.rulesResult.items.findIndex(item => item.classification === c.Code) >= 0) {
                        this.classificationFilter.push({
                            Code: c.Code,
                            Description: c.Description,
                            selected: true
                        });
                    }
                });

                this.searchComplete = true;
                this.disableButton = false;

            })
        this.selectedTaxCountriesList = [];
        this.searchRequest.taxCountries.forEach(c => {
            const country = this.result.LookupData.TaxCountry.Items.Item.find(t => t.Name === c);
            this.selectedTaxCountriesList.push(country);
            this.selectedTaxCountriesHaveLinks = !this.selectedTaxCountriesList.every(el => (typeof el.CrystalLink === 'undefined' || el.CrystalLink === null || el.CrystalLink === ""));
        })
        this.selectedTerritoriesList = [];
        this.searchRequest.territories.forEach(c => {
            const territories = this.result.LookupData.Territory.Items.Item.find(t => t.Name === c);
            this.selectedTerritoriesList.push(territories);
            this.selectedTerritoriesHaveLinks = !this.selectedTerritoriesList.every(el => (typeof el.CrystalLink === 'undefined' || el.CrystalLink === null || el.CrystalLink === ""));
        })

    }


    changeMarket() {

        this.filteredCOBList = this.result.LookupData.MarketClassOfBusiness.Items.Item.filter(el => this.searchRequest.marketString === el.Market && el.ClassOfBusiness !== "(Not Selected)");
        this.searchRequest.classOfBusinesses = [];

    }
    expandAll() {
        this.panelExpanded = true;
    }
    collapseAll() {
        this.panelExpanded = false;
    }

    select(item: Item) {
        this.filteredRuleResult.items.find(i => i.reference === item.reference).selected = item.selected;
    }

    applyFilter() {
        // filter results
        this.filteredRuleResult = Object.assign({}, this.rulesResult);
        const filter: string[] = [];
        this.classificationFilter.forEach(
            key => {
                if (key.selected) {
                    filter.push(key.Code);
                }
            });
        this.filteredRuleResult.items = this.filteredRuleResult.items.filter(r => filter.includes(r.classification));
        this.filteredRuleResult.count = this.filteredRuleResult.items.length;

        // group filtered results

        const groupBySection = {};

        this.filteredRuleResult.items.forEach(function (a) {
            groupBySection[a.section] = groupBySection[a.section] || [];
            groupBySection[a.section].push({ reference: a.reference, classification: a.classification, description: a.description, selected: a.selected, order: a.order });

        });

        this.groupedResults = groupBySection;
        this.groupedResultKeys = [];
        Object.keys(this.groupedResults).forEach(key => {
            this.groupedResultKeys.push(key)
        });
        this.groupedResultKeys.forEach(k => this.groupedResults[k] = this.groupedResults[k].sort((a, b) => (a.reference > b.reference) ? 1 : -1));
    }

    applyBAFilter(filterVal: string) {
        // filter results
        const filterKeyword = filterVal === 'Detail View' ? 'Detail' : 'Summary';
        this.chosenviewType = filterVal;
        this.filteredRuleResult = Object.assign({}, this.rulesResult);
        this.filteredRuleResult.items = this.filteredRuleResult.items.filter(f => f.viewType.indexOf(filterKeyword) !== -1 || f.viewType.indexOf('(Not Selected)') !== -1);
        this.filteredRuleResult.count = this.filteredRuleResult.items.length;
        // group filtered results

        const groupBySection = {};

        this.filteredRuleResult.items.forEach(function (a) {
            groupBySection[a.section] = groupBySection[a.section] || [];
            groupBySection[a.section].push({ reference: a.reference, classification: a.classification, description: a.description, selected: a.selected, order: a.order });

        });

        this.groupedResults = groupBySection;
        this.groupedResultKeys = [];
        Object.keys(this.groupedResults).forEach(key => {
            this.groupedResultKeys.push(key)
        });
        this.groupedResultKeys.forEach(k => this.groupedResults[k] = this.groupedResults[k].sort((a, b) => (a.reference > b.reference) ? 1 : -1));
    }

    generateExcel() {
        this.exportToExcelInProgress = true;
        this.excelService.generateExcel(this.filteredRuleResult, this.searchRequest, this.currentType);
        this.exportToExcelInProgress = false;
    }
    generatePdf() {
        this.exportToPdfInProgress = true;
        const sortedResults = this.filteredRuleResult;
        sortedResults.items = this.filteredRuleResult.items.sort((a, b) => (a.reference > b.reference) ? 1 : -1);
        this.pdfService.exportPdf(sortedResults, this.searchRequest, this.currentType);
        this.exportToPdfInProgress = false;
    }
    openNotes() {
        const url = '/notes';
        const w = screen.width * 0.25;
        const h = screen.height;
        const l = screen.width - w - 15;
        let params = 'width=' + w;
        params += ', height=' + h;
        params += ', top=0'
        params += ', left=' + l;
        params += ', fullscreen=no';
        params += ', directories=no';
        params += ', location=no';
        params += ', menubar=no';
        params += ', resizable=no';
        params += ', scrollbars=yes';
        params += ', status=no';
        params += ', toolbar=no';
        const newwin = window.open(url, 'notes', params);
        if (window.focus) { newwin.focus() }
        return false;
    }

}
