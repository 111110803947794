import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
// material modules
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
//components and services
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { FAQComponent } from './faq/faq.component';
import { TimePipe } from './shared/time.pipe';

import { CookieService } from './services/cookie.service';
import { ApiDataService } from './services/api-data.service';
import { ExcelService } from './services/excel.service';
import { PdfService } from './services/pdf.service';
import { CryptographyService } from './services/cryptography.service';
import { ReleaseHistoryComponent } from './release-history/release-history.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { FooterComponent } from './footer/footer.component';
import { NotesComponent } from './notes/notes.component';
import { LocalDateTimePipe } from './pipes/local.dateTime.pipe';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'search/:type', component: SearchComponent },
    { path: 'faq', component: FAQComponent },
    { path: 'release-history', component: ReleaseHistoryComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: 'notes', component: NotesComponent },
    { path: '**', redirectTo: 'home' }
];

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        name: "cookieLaw",
        domain: window.location.hostname,
        expiryDays: 30,
        secure: true
    },
    position: 'bottom',
    palette: {
        popup: {
            background: '#123f89',
            border: 'solid red 2px'
        },
        button: {
            background: '#f1d600',
            text: '#000000'
        }
    },
    content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: 'https://www.lloyds.com/common/cookies-policy',
        policy: 'Cookie Policy'
    },
    theme: 'edgeless',
    type: 'info'
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        SearchComponent,
        FAQComponent,
        ReleaseHistoryComponent,
        MaintenanceComponent,
        NavMenuComponent,
        TimePipe,
        FooterComponent,
        NotesComponent,
        LocalDateTimePipe
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        NgcCookieConsentModule.forRoot(cookieConfig),
        InfiniteScrollModule,
        NgxSpinnerModule,

        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatToolbarModule,
        MatIconModule,
        MatTableModule,

        HttpClientModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/' }, CookieService, ApiDataService, ExcelService, PdfService, CryptographyService],
    bootstrap: [AppComponent]
})
export class AppModule { }
