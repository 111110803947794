import { Component } from '@angular/core';

@Component({
    selector: 'notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.css']
})
export class NotesComponent {

    url: string;
    
    scrollTo(elm: string) {
        const elmnt = document.getElementById(elm);
        if (elmnt) { elmnt.scrollIntoView(); }
    }
}
