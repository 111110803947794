import { Component, OnInit } from '@angular/core';
import { ApiDataService } from '../services/api-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

    lcc_logo = 'Scripts/libs/assets/LCC.png';
    version: string;
    rbVersion: string;

    constructor(
        private apiDataService: ApiDataService) {

    }
    ngOnInit(): void {

        this.version = this.apiDataService.version.getValue();
        if (typeof this.version === 'undefined' || this.version === "" || this.version === null) {
            this.apiDataService.getVersion()
                .subscribe(result => {
                    this.version = result.body;
                    if (typeof this.version === 'undefined' || this.version === "" || this.version === null) {
                        this.apiDataService.version.next(this.version);
                    }
                })
        }
        this.rbVersion = this.apiDataService.rbVersion.getValue();
        if (typeof this.rbVersion === 'undefined' || this.rbVersion === "" || this.rbVersion === null) {
            this.apiDataService.getRbServerversion()
                .subscribe(result => {
                    this.rbVersion = result.body;
                    if (typeof this.rbVersion === 'undefined' || this.rbVersion === "" || this.rbVersion === null) {
                        this.apiDataService.rbVersion.next(this.rbVersion);
                    }
                })
        }
        //console.log(document.getElementsByClassName('footer-container')[0].clientHeight);
    }

}
