
<html>
<head>
    <title>XIS Notes: XIS Instructions</title>
</head>
<body class="layout-c1">
    <a name="TOPOFDOC" id="TOPOFDOC"></a>
    <div id="ldc-header">
        <div id="headerWrap">
            <div id="logo">
                <h1>XIS Notes</h1>
            </div>
            <h2 id="applicationTitle"></h2>
            <ul id="utility">
            </ul>
        </div>
    </div>
    <br />

    <div id="columnnote" class="noPrint">
        <div id="ColumnToolBoxnote">
            <ul>
                <li id="ClosePagenote"><a href="#" onclick="window.close();" id="A1">Close this window</a></li>
                <li id="PrintPagenote"><A title="Click here to print this page" onclick="window.print();return false;" href="#">Print this page</A> </li>
            </ul>
        </div>
    </div>

    <div>
        <h2 class="Notemargin">Lloyd's Notes and XIS Instructions</h2>
    </div>
    <div>
        <p class="normalNotetext">
            Please click on the links below to see further detail concerning the respective Note.
        </p>
    </div>
    <div class="linkNotetext">
        <a (click)="scrollTo('f1');">Note [1]</a><br>
        <a (click)="scrollTo('f2');">Note [2]</a><br>
        <a (click)="scrollTo('f3');">Note [3]</a><br>
        <a (click)="scrollTo('f4');">Note [4]</a><br>
        <a (click)="scrollTo('f5');">Note [5]</a><br>
        <a (click)="scrollTo('f6');">Note [6]</a><br>
        <a (click)="scrollTo('f7');">Note [7] deleted July 2008</a> <br>
        <a (click)="scrollTo('f8');">Note [8]</a><br>
        <a (click)="scrollTo('f9');">Note [9]</a><br>
        <a (click)="scrollTo('f10');">Note [10]</a><br>
        <a (click)="scrollTo('f11');">Note [11]</a><br>
        <a (click)="scrollTo('f12');">Note [12]</a><br>
        <a (click)="scrollTo('f13');">Note [13]</a><br>
        <a (click)="scrollTo('f14');">Note [14]</a><br>
        <a (click)="scrollTo('f15');">Note [15]</a><br>
        <a (click)="scrollTo('f16');">Note [16]</a><br>
        <a (click)="scrollTo('f17');">Note [17]</a><br>
        <a (click)="scrollTo('f18');">Note [18] deleted July 2008</a><br>
    </div>
    <br />
    <div>

        <p>
            <span class="SectionHeading">
                <a id="f1"></a>Note [1]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - contracts are not to be rejected for minor typos / omissions in the coverholder name or address.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f2"></a>Note [2]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - when checking a non-model agreement: the coverholder must not be permitted to sub-delegate its authority to bind insurance or issue documents evidencing insurance cover AND appropriate cancellation/termination, expiry, jurisdiction/dispute, separate bank account, joint certificate, taxation and financial guarantee exclusion clauses must be included.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f3"></a>Note [3]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - if any of these basic data entry fields on the slip schedule have not been completed, the contract is to be rejected at Grade A.  Please refer to the guidance notes to the QA Tool for an explanation of how these data entry checks are intended to be used.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f4" href="#f4"></a>Note [4]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - XIS may accept reference to a particular level of seniority / authority in respect of who may sign and issue the documents evidencing insurance cover.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f5"></a>Note [5]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - this field may not be completed on some prior submit BA if all underwriters agree that the limit is to be set/agreed by the slip leader for each contract.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f6"></a>Note [6]
            </span>
        </p>
        <p class="normalNotetext">
            Note that the term &lsquo;USA&rsquo; or &lsquo;US&rsquo; is deemed to include  Kentucky, Illinois and the USVI.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f8"></a>Note [8]
            </span>
        </p>
        <p class="normalNotetext">
            XIS Instruction: - reference to a TPA agreement is permissible and the TPA Agreement may be attached to the BA but this section of the schedule is intended to set out the coverholder&rsquo;s claims handling / settlement authority.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f9"></a>Note [9]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - the intention of this check is clause clarity &ndash; to pick up situations when clauses, while neither inconsistent with each other or the wording (per check 1.992) nor absent (per check 1.993) nor subject to specific QA Tool checks (per QA Tool), are nevertheless so unclear that the contract as a whole is undermined.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f10"></a>Note [10]
            </span>
        </p>
        <p class="normalNotetext">
            Please note that a slip warranty or letter of indemnity is needed to support any photocopy slip submitted to XIS for checking.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f11"></a>Note [11]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - contracts are not to be rejected if clear and stand alone clauses are set out in technically incorrect but closely aligned sections of the slip.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f12"></a>Note [12]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - the intention of this check is contract integrity - to pick up situations when it is fundamentally unclear what clauses and/or attachments are to be included in the contract such that the contract as a whole is undermined.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f13"></a>Note [13]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - XIS also to ensure that the written syndicate details match the signed syndicate details.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f14"></a>Note [14]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - XIS may accept references to &lsquo;as UMR&rsquo; or &lsquo;as Agreement Number&rsquo; or similar (where the UMR and the Agreement number are the same); and a short form/abbreviated coverholder name (where the identity of the coverholder is clear) for endorsement reference details, subject to the overriding need for clarity as to the binding authority to which the endorsement attaches.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f15"></a>Note [15]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - XIS may accept references similar to &lsquo;from the date the coverholder signs this endorsement&rsquo; and references similar to &lsquo;from inception of the contract&rsquo; - when there is no uncertainty as to the date to which it refers.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f16" href="#f16"></a>Note [16]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - conflicts with the unaffected provisions of the BA Contract should ONLY be queried if the resultant endorsed contract is fundamentally undermined by the significance of the conflict.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
        <p>
            <span class="SectionHeading">
                <a id="f17"></a>Note [17]
            </span>
        </p>
        <p class="normalNotetext">
            XIS instruction: - the leading underwriter's signature witnessing the coverholder&rsquo;s acceptance of the contract is NOT acceptable as an agreement for the endorsement changes.
        </p>
        <a (click)="scrollTo('logo');" class="gototoplink">Go To Top</a>
        <br>
        <br>
    </div>

</body>
</html>
