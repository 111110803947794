<div class="wrapper">
    <div class="container mt-3 content">
        <h1 id="pageTitle">Maintenance</h1>
        <p *ngIf="!result">Loading ...</p>
        <h3 class="header mt-3">Upcoming Maintenance</h3>
        <table *ngIf="upcomingMaintenance && upcomingMaintenance.length" mat-table [dataSource]="upcomingMaintenance" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of upcomingMaintenanceColumnsToDisplay">
                <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="column === 'start' || column === 'end'">{{element[column] | localDateTime: 'shortTime'}}</ng-container>
                    <ng-container *ngIf="column === 'duration'">
                        {{element[column] | time}}
                        <mat-icon *ngIf="expandedElement !== element" aria-hidden="false" aria-label="Expand more">expand_more</mat-icon>
                        <mat-icon *ngIf="expandedElement === element" aria-hidden="false" aria-label="Expand less">expand_less</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="column === 'version'">{{element.releaseHistory.version}}</ng-container>
                    <ng-container *ngIf="column === 'message'">There is currently no planned maintenance</ng-container>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="upcomingMaintenanceColumnsToDisplay.length">
                    <div class="maintenance-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="maintenance-element-description">
                            <div>
                                <span class="maintenance-description-text" *ngIf="element.releaseHistory.detail" [innerHtml]="element.releaseHistory.detail" style="display:block;"></span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="upcomingMaintenanceColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: upcomingMaintenanceColumnsToDisplay;"
                class="maintenance-element-row"
                [class.maintenance-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="maintenance-detail-row"></tr>
        </table>

        <h3 class="header mt-3" *ngIf="pastMaintenance && pastMaintenance.length">Past Maintenance</h3>

        <table *ngIf="pastMaintenance && pastMaintenance.length" mat-table [dataSource]="pastMaintenance" multiTemplateDataRows class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of pastMaintenanceColumnsToDisplay">
                <th mat-header-cell *matHeaderCellDef> {{column | titlecase}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="column === 'start' || column === 'end'">{{element[column] | localDateTime:'shortTime'}}</ng-container>
                    <ng-container *ngIf="column === 'duration'">
                        {{element[column] | time}}
                        <mat-icon *ngIf="expandedElement !== element" aria-hidden="false" aria-label="Expand more">expand_more</mat-icon>
                        <mat-icon *ngIf="expandedElement === element" aria-hidden="false" aria-label="Expand less">expand_less</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="column === 'version'">{{element.releaseHistory.version}}</ng-container>
                    <ng-container *ngIf="column === 'message'">There is no past maintenance</ng-container>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="pastMaintenanceColumnsToDisplay.length">
                    <div class="maintenance-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="maintenance-element-description">
                            <div>
                                <span class="maintenance-description-text" *ngIf="element.releaseHistory.detail" [innerHtml]="element.releaseHistory.detail" style="display:block;"></span>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pastMaintenanceColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: pastMaintenanceColumnsToDisplay;"
                class="maintenance-element-row"
                [class.maintenance-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="maintenance-detail-row"></tr>
        </table>
        <div class="center mt-3">
            <button mat-raised-button color="primary" *ngIf="result && result.items.length < result.totalRows" (click)="loadMoreResults();">
                Show More Items
                <mat-icon *ngIf="searchInProgress">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            <button mat-raised-button disabled *ngIf="result && result.items.length == result.totalRows">No More Items to Show</button>
        </div>
    </div>
</div>


