import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvancedReleaseHistorySearchRequest, SearchReleaseHistoryResponse, ReleaseHistory, SearchRuleCommentsRequest, SearchRuleCommentsResponse, CommentData } from '../models/models'
import { ApiDataService } from '../services/api-data.service';
import { CookieService } from '../services/cookie.service';
import { CryptographyService } from '../services/cryptography.service';

@Component({
  selector: 'release-history',
  templateUrl: './release-history.component.html',
    styleUrls: ['./release-history.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class ReleaseHistoryComponent implements OnInit {

    releaseHistorySearchCriteria: AdvancedReleaseHistorySearchRequest = {
        Product: 'QATool',
        pageNumber: 1,
        PageSize: 3,
        SearchColumn: 'All',
        SearchCriteria: '%',
        SortByColumn: 'dateUpdated',
        SortOrder: 'Desc'
    };
    releaseHistoryResult: SearchReleaseHistoryResponse;
    releaseHistoryColumnsToDisplay = ['version', 'updated'];
    expandedReleaseHistoryElement: ReleaseHistory | null;
    releaseHistorySearchInProgress: boolean;

    ruleCommentsSearchCriteria: SearchRuleCommentsRequest = {
        filter: 'Last',
        pageNumber: 1,
        pageSize: 10,
        searchColumn: 'None',
        searchCriteria: '%',
        sortByColumn: 'dateUpdated',
        sortOrder: 'Desc'
    };
    ruleCommentsResult: SearchRuleCommentsResponse;
    ruleCommentsColumnsToDisplay = ['reference', 'title', 'businessArea', 'updated'];
    expandedRuleCommentsElement: CommentData | null;
    ruleCommentsSearchInProgress: boolean;

    constructor(private apiDataService: ApiDataService, private cookieService: CookieService, private cryptographyService: CryptographyService) { }

    ngOnInit(): void {
        this.apiDataService.searchRuleComments(this.ruleCommentsSearchCriteria)
            .subscribe(result => {
                this.ruleCommentsResult = result.body;
            })
        this.apiDataService.searchReleaseHistory(this.releaseHistorySearchCriteria)
            .subscribe(result => {
                this.releaseHistoryResult = result.body;
            })
    }
    loadMoreReleaseHistoryResults() {
        this.releaseHistorySearchInProgress = true;
        const req = this.releaseHistorySearchCriteria;
        req.pageNumber = this.releaseHistorySearchCriteria.pageNumber + 1;

        this.apiDataService.searchReleaseHistory(req)
            .subscribe(result => {

                if (this.releaseHistoryResult.success === true) {
                    this.releaseHistoryResult.items = this.releaseHistoryResult.items.concat(result.body.items);
                    this.releaseHistorySearchCriteria.pageNumber = req.pageNumber;
                    this.releaseHistorySearchInProgress = false;
                }
            });
    }
    loadMoreRuleCommentsResults() {
        this.ruleCommentsSearchInProgress = true;
        const req = this.ruleCommentsSearchCriteria;
        req.pageNumber = this.ruleCommentsSearchCriteria.pageNumber + 1;

        this.apiDataService.searchRuleComments(req)
            .subscribe(result => {
                if (this.ruleCommentsResult.success === true) {
                    this.ruleCommentsResult.items = this.ruleCommentsResult.items.concat(result.body.items);
                    this.ruleCommentsSearchCriteria.pageNumber = req.pageNumber;
                    this.ruleCommentsSearchInProgress = false;
                }
            });
    }

}
