import { Component, OnInit } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    url: string;
    browserIE11: boolean;

    constructor(private ccService: NgcCookieConsentService,
        private router: Router) {

        this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationEnd) {
                    this.url = router.url;                   
                }
            }
        );

    }

    ngOnInit() {

        this.browserIE11 = window.navigator.userAgent.indexOf("Trident/") > -1;
    }

}
