import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiDataService } from '../services/api-data.service';
import { ContactDetailsResponse, SearchMaintenanceResponse, AdvancedMaintenanceSearchRequest } from '../models/models';
import { CookieService } from '../services/cookie.service';
import { CryptographyService } from '../services/cryptography.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    contactDetails: ContactDetailsResponse;
    maintenanceResult: SearchMaintenanceResponse;
    maintenanceSearchCriteria: AdvancedMaintenanceSearchRequest = {
        Product: 'QATool',
        pageNumber: 1,
        PageSize: 1,
        SearchColumn: 'None',
        SearchCriteria: '%',
        SortByColumn: 'start',
        SortOrder: 'Desc'
    };
    showMaintenance: boolean;
    result: any;

    constructor(private router: Router,
        private cookieService: CookieService,
        private apiDataService: ApiDataService,
        private cryptographyService: CryptographyService) {

        this.showMaintenance = false;

        this.apiDataService.getContactDetails()
            .subscribe(result => {
                if (result) {
                    this.contactDetails = result.body;
                }
            });
        this.getBusinessAreaData();

        this.apiDataService.searchMaintenance(this.maintenanceSearchCriteria)
            .subscribe(result => {
                this.maintenanceResult = result.body;

                if (this.maintenanceResult.items && this.maintenanceResult.items[0]) {
                    const now = new Date().valueOf();
                    const start = new Date(this.maintenanceResult.items[0].start).valueOf();
                    this.showMaintenance = start >= now;
                }
            });

    }
    getBusinessAreaData() {

        let lookupDataCollection = this.apiDataService.businessArea.value;
        if (typeof lookupDataCollection === 'undefined' || lookupDataCollection === null || lookupDataCollection === []) {
            let lookupData = this.cookieService.get('businessArea');

            if (typeof lookupDataCollection !== 'undefined' || lookupDataCollection !== null || lookupDataCollection !== {}) {
                lookupData = this.cryptographyService.decryptData(lookupData);
                if (lookupData && lookupData !== "") {
                    lookupDataCollection = JSON.parse(decodeURIComponent(lookupData));
                }
            }
        }

        if (lookupDataCollection && lookupDataCollection.Items && lookupDataCollection.Items.Item.length > 0) {
            this.result = {
                LookupData: {
                    BusinessArea: lookupDataCollection
                }
            };
        } else {
            this.apiDataService.getLookupDataByType("BusinessArea")
                .subscribe(result => {
                    if (result) {
                        this.result = JSON.parse(result.body.xml);

                        if (this.result && this.result.LookupData) {
                            // sort Business Area data descending to make sure Open market is displayed first
                            this.result.LookupData.BusinessArea.Items.Item.sort((a, b) => {
                                if (a.Code > b.Code)
                                    return -1;
                                if (a.Code < b.Code)
                                    return 1;
                                return 0;
                            });
                            this.apiDataService.businessArea.next(this.result.LookupData.BusinessArea);

                            this.cookieService.set('businessArea', this.cryptographyService.encryptData(JSON.stringify(this.result.LookupData.BusinessArea)), 0.01, undefined, undefined, true, 'None');
                        }
                    }
                });
        }
    }
    public loadLookupData(): void {

        const lookupData = sessionStorage.getItem('lookup');
        if (lookupData && lookupData !== null) {
            return;
        } else {
            this.apiDataService.getLookupData()
                .subscribe(result => {
                    this.result = JSON.parse(result.body.xml);
                    if (this.result.LookupData) {
                        //have to use session storage instead of cookies as data are too big for cookies
                        this.apiDataService.lookups.next(this.result.LookupData);
                        // sort Business Area data descending to make sure Open market is displayed first
                        this.result.LookupData.BusinessArea.Items.Item.sort((a, b) => {
                            if (a.Code > b.Code)
                                return -1;
                            if (a.Code < b.Code)
                                return 1;
                            return 0;
                        });
                        sessionStorage.setItem('lookup', this.cryptographyService.encryptData(JSON.stringify(this.result.LookupData)));
                    }
                })
        }
    }
    ngOnInit(): void {
        this.showMaintenance = false;
        this.loadLookupData();
    }

}
