<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light box-shadow">
        <div class="container">
            <div class="lloyds_logo_container">
                <a href="https://www.lloyds.com" target="_blank">
                    <img class="lloyds_logo" [src]="lloyds_logo" alt="Lloyds Logo" />
                </a>
            </div>
            <button class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-label="Toggle navigation"
                    [attr.aria-expanded]="isExpanded"
                    (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
                 [ngClass]="{ show: isExpanded }">
                <ul class="navbar-nav flex-grow">
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark-inverse" [routerLinkActive]="['link-active']" [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark-inverse" [routerLink]="['/faq']">FAQ</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark-inverse" [routerLink]="['/release-history']">Change History</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark-inverse" [routerLink]="['/maintenance']">Maintenance</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
