import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {
    Enum,
    ContactDetailsResponse,
    LoookupResponse, Collection, LookupItem, AdvancedRuleSearchRequest, SearchRulesResponse,
    AdvancedFaqSearchRequest, SearchFaqResponse,
    AdvancedMaintenanceSearchRequest, SearchMaintenanceResponse,
    AdvancedReleaseHistorySearchRequest, SearchReleaseHistoryResponse,
    SearchRuleCommentsRequest, SearchRuleCommentsResponse,
    GetFormDefinitionRequest, GetFormDefinitionResponse
} from '../models/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiDataService {

    public lookups: BehaviorSubject<Collection> = new BehaviorSubject(<Collection>{});
    public businessArea: BehaviorSubject<any> = new BehaviorSubject(<any>{});
    public rbVersion: BehaviorSubject<string> = new BehaviorSubject(<string>"");
    public version: BehaviorSubject<string> = new BehaviorSubject(<string>"");
    private httpOptions;

    constructor(private http: HttpClient)
    {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }),
            //withCredentials: true,
            observe: 'response'
        };

    }

    parseLinkHeader(header) {
        if (header.length === 0) {
            return;
        }
    }
    getSettings(): Observable<any> {
        const url = `/api/settings`;
        return this.http.get<string>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getVersion(): Observable<any> {
        const url = `/api/about/version`;
        return this.http.get<string>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getRbServerversion(): Observable<any> {
        const url = `/api/about/rbserverversion`;
        return this.http.get<string>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getContactDetails(): Observable<any> {
        const url = `/api/config/contactdetails`;
        return this.http.get<ContactDetailsResponse>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getLookupData(): Observable<any>{
      const url = `/api/lookup`;
        return this.http.get<LoookupResponse>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getLookupDataByType(type: string): Observable<any> {
        const url = `/api/lookup/${type}`;
        //return this.http.get(url, this.httpOptions).pipe(catchError(this.handleError));
        return this.http.get<LoookupResponse>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getRules(): Observable<any> {
        const url = `/api/rules`;
        return this.http.get<SearchRulesResponse>(url, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    searchRules(request: AdvancedRuleSearchRequest): Observable<any> {
        request.documentTypes = [request.documentType];
        const url = `/api/rules`;
        return this.http.post<SearchRulesResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    searchFaq(request: AdvancedFaqSearchRequest): Observable<any> {
        const url = `/api/faq/search`;
        return this.http.post<SearchFaqResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getFaqById(id: string): Observable<any> {
        const url = `/api/faq/`;
        return this.http.get<SearchFaqResponse>(url + id, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    searchMaintenance(request: AdvancedMaintenanceSearchRequest): Observable<any> {
        const url = `/api/maintenance/search`;
        return this.http.post<SearchMaintenanceResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getMaintenanceById(id: string): Observable<any> {
        const url = `/api/maintenance/`;
        return this.http.get<SearchMaintenanceResponse>(url + id, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    searchReleaseHistory(request: AdvancedReleaseHistorySearchRequest): Observable<any> {
        const url = `/api/releasehistory/search`;
        return this.http.post<SearchReleaseHistoryResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getReleaseHistoryById(id: string): Observable<any> {
        const url = `/api/releasehistory/`;
        return this.http.get<SearchReleaseHistoryResponse>(url + id, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    searchRuleComments(request: SearchRuleCommentsRequest): Observable<any> {
        const url = `/api/comments`;
        return this.http.post<SearchRuleCommentsResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    getFormDefinition(request: GetFormDefinitionRequest): Observable<any> {
        const url = `/api/form`;
        return this.http.post<GetFormDefinitionResponse>(url, request, this.httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getFormVisibilityEnum(requestUrl: string): Observable<any> {
        const url = `/api/enum/`;
        return this.http.get<Enum[]>(url + requestUrl, this.httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
