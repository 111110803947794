import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})

export class TimePipe implements PipeTransform {
    transform(value: number): string {
        if (value) {
            let hours: number = value / 60;
            let wholeHours = Math.round(hours);
            let min = value - (wholeHours * 60);
            let timeString = wholeHours + 'h ' + min + 'min';
            return timeString;
        }
    }
}