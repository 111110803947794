import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvancedFaqSearchRequest, SearchFaqResponse } from '../models/models'
import { ApiDataService } from '../services/api-data.service';
import { CookieService } from '../services/cookie.service';
import { CryptographyService } from '../services/cryptography.service';

@Component({
    selector: 'faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
    panelOpenState = false;
    panelExpanded = false;
    searchCriteria: AdvancedFaqSearchRequest = {
        Product: 'QATool',
        pageNumber: 1,
        PageSize: 25,
        SearchColumn: 'All',
        SearchCriteria: '%',
        SortByColumn: 'order',
        SortOrder: 'Asc'
    };
    result: SearchFaqResponse;
    notscrolly: boolean;
    notEmptyPost: boolean;

    constructor(private http: HttpClient,
        private spinner: NgxSpinnerService,
        private apiDataService: ApiDataService,
        private cookieService: CookieService,
        private cryptographyService: CryptographyService) { }

    ngOnInit(): void {
        this.notscrolly = true;
        this.notEmptyPost = true;
        this.apiDataService.searchFaq(this.searchCriteria)
            .subscribe(result => {
                if (result.body.success) {
                    this.result = result.body;
                }
            });
    }

    onScroll() {
        if (this.notscrolly && this.notEmptyPost) {
            this.spinner.show();
            this.notscrolly = false;
            this.loadNextPost();
        }
    }

    loadNextPost() {
        if (this.result.totalRows === this.result.items.length) {
            this.spinner.hide();
            this.notscrolly = true;
            return;
        }
        if (this.result.totalRows > this.result.items.length) {
            this.searchCriteria.pageNumber = this.searchCriteria.pageNumber + 1;
            this.apiDataService.searchFaq(this.searchCriteria)
                .subscribe(result => {
                    this.spinner.hide();
                    if (result.body.success) {
                        this.result.items = this.result.items.concat(result.body.items);
                    }
                    this.notscrolly = true;
                });

        }

    }

}
