import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvancedMaintenanceSearchRequest, SearchMaintenanceResponse, ReleaseHistory, MaintenanceItem } from '../models/models';
import { ApiDataService } from '../services/api-data.service';
import { CookieService } from '../services/cookie.service';
import { CryptographyService } from '../services/cryptography.service';

@Component({
    selector: 'maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class MaintenanceComponent implements OnInit {

    searchCriteria: AdvancedMaintenanceSearchRequest = {
        Product: 'QATool',
        pageNumber: 1,
        PageSize: 5,
        SearchColumn: 'None',
        SearchCriteria: '%',
        SortByColumn: 'start',
        SortOrder: 'Desc'
    };
    result: SearchMaintenanceResponse;
    upcomingMaintenance: MaintenanceItem[] = [];

    pastMaintenance: MaintenanceItem[] = [];
    upcomingMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];
    pastMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];
    expandedElement: ReleaseHistory | null;
    searchInProgress: boolean;
    constructor(private apiDataService: ApiDataService, private cookieService: CookieService, private cryptographyService: CryptographyService) { }

    ngOnInit(): void {
        this.searchInProgress = true;
        this.apiDataService.searchMaintenance(this.searchCriteria)
            .subscribe(result => {
                this.result = result.body;
                this.upcomingMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];
                this.pastMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset();
                const nowValue = now.valueOf() + timezoneOffset * 60000;
                this.result.items.forEach(item => {
                    item.show = new Date(item.end).valueOf() >= nowValue;
                    if (new Date(item.end).valueOf() >= nowValue) {
                        this.upcomingMaintenance.push(item);
                    } else {
                        this.pastMaintenance.push(item);
                    }
                });
                if (this.upcomingMaintenance.length === 0) {
                    this.upcomingMaintenanceColumnsToDisplay = ['message'];
                    this.upcomingMaintenance.push({
                        dateCreated: null,
                        dateUpdated: null,
                        duration: null,
                        end: null,
                        id: "",
                        releaseHistory: { version: null, dateCreated: null, dateUpdated: null, detail: null, id: null, product: null },
                        show: true,
                        start: null
                    });
                }
                if (this.pastMaintenance.length === 0) {
                    this.pastMaintenanceColumnsToDisplay = ['message'];
                    this.pastMaintenance.push({
                        dateCreated: null,
                        dateUpdated: null,
                        duration: null,
                        end: null,
                        id: "",
                        releaseHistory: { version: null, dateCreated: null, dateUpdated: null, detail: null, id: null, product: null },
                        show: true,
                        start: null
                    });
                }
                this.searchInProgress = false;
            });
    }

    loadMoreResults() {
        this.searchInProgress = true;
        const req = this.searchCriteria;
        req.pageNumber = this.searchCriteria.pageNumber + 1;

        this.apiDataService.searchMaintenance(req)
            .subscribe(result => {
                if (this.result.success === true) {
                    this.result.items = this.result.items.concat(result.body.items);

                    const now = new Date().valueOf();
                    this.upcomingMaintenance = [];
                    this.pastMaintenance = [];
                    this.result.items.forEach(item => {
                        item.show = new Date(item.start).valueOf() >= now;
                        if (new Date(item.start).valueOf() >= now) {
                            this.upcomingMaintenance.push(item);
                        } else {
                            this.pastMaintenance.push(item);
                        }
                    });
                    if (this.upcomingMaintenance.length === 0) {
                        this.upcomingMaintenanceColumnsToDisplay = ['message'];
                        this.upcomingMaintenance.push({
                            dateCreated: null,
                            dateUpdated: null,
                            duration: null,
                            end: null,
                            id: "",
                            releaseHistory: { version: null, dateCreated: null, dateUpdated: null, detail: null, id: null, product: null },
                            show: true,
                            start: null
                        });
                    } else {
                        this.upcomingMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];

                    }
                    if (this.pastMaintenance.length === 0) {
                        this.pastMaintenanceColumnsToDisplay = ['message'];
                        this.pastMaintenance.push({
                            dateCreated: null,
                            dateUpdated: null,
                            duration: null,
                            end: null,
                            id: "",
                            releaseHistory: { version: null, dateCreated: null, dateUpdated: null, detail: null, id: null, product: null },
                            show: true,
                            start: null
                        });
                    } else {
                        this.pastMaintenanceColumnsToDisplay = ['version', 'start', 'end', 'duration'];
                    }
                    this.searchCriteria.pageNumber = req.pageNumber;
                    this.searchInProgress = false;
                }
            });
    }

}
