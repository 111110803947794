<div class="wrapper">
    <div class="container">
        <p *ngIf="!result">
            Loading ...
            <mat-icon *ngIf="!result" class="black">
                <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
        </p>

        <!--Right side infomration panel-->
        <div class="row mt-3 mb-3">
            <div class="col-lg-8">
                <h1 *ngIf="currentType && currentType.Name" id="pageTitle">
                    <img *ngIf="type=='OM'" [src]="OM_logo" alt="currentType.Name" />
                    <img *ngIf="type=='BA'" [src]="BA_logo" alt="currentType.Name" />
                    {{currentType.Name}} <span *ngIf="searchComplete && groupedResults">Results</span>
                </h1>
                <div *ngIf="searchComplete && groupedResults">{{resultsHeaderDescription}}</div>
            </div>
            <div class="col-lg-4 tool-help">
                <div>
                    <h3 class="header">
                        Tool Help
                    </h3>
                    <p>
                        For more detailed regulatory information and how to determine location of risk please use <a class="arrowLink" href="http://crystal.lloyds.com" target="_blank">Crystal</a>
                    </p>
                </div>
            </div>
        </div>
        <!--Search parameters-->
        <div *ngIf="result && result.LookupData && !searchComplete" class="row mb-3">
            <div class="col-lg-8">
                <div class="row search-parameters-header pb-2 pt-2">
                    <div class="col-lg-8 search-parameters-header-text">
                        Make a selection from all the dropdown boxes below and click 'view results' to see all requirements relevant to you.
                        All fields are mandatory.
                    </div>
                    <div class="col-lg-4 search-parameters-buttons">
                        <button mat-raised-button color="accent" (click)="resetSearch();" [disabled]="disableButton === true" class="mr-1">Reset</button>
                        <button mat-raised-button color="accent" (click)="searchResults();"
                                [disabled]="(selectedClassesOfBusinessFieldIncluded && selectedClassesOfBusiness.hasError('required'))
                                 || (selectedTerritoriesFieldIncluded && selectedTerritories.hasError('required'))
                                 || (selectedTaxCountriesFieldIncluded && selectedTaxCountries.hasError('required'))
                                 || (selectedDocumentTypeFieldIncluded && selectedDocumentType.hasError('required'))
                                 || (selectedInsuranceTypesFieldIncluded && selectedInsuranceTypes.hasError('required'))
                                 || (selectedMarketFieldIncluded && selectedMarket.hasError('required'))
                                 || disableButton === true">
                            View Results
                            <mat-icon *ngIf="searchInProgress">
                                <mat-spinner diameter="20"></mat-spinner>
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 results-header pt-3 pb-3">
                <form>
                    <ng-container *ngIf="formDefinitionResponse && formDefinitionResponse.item && formDefinitionResponse.item.searchFields">
                        <ng-container *ngFor="let formField of formDefinitionResponse.item.searchFields">
                            <ng-container *ngIf="formField.internalName === 'FIELD_DOCUMENT_TYPE'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedDocumentType"
                                                    [(value)]="searchRequest.documentType"
                                                    [multiple]="formField.multiSelectLimit>0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of filteredDocumentTypeList" [value]="item.DocumentTypeName">
                                                {{item.DocumentTypeName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedDocumentType.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formField.internalName === 'FIELD_TERRITORY'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedTerritories"
                                                    [(value)]="searchRequest.territories"
                                                    [multiple]="formField.multiSelectLimit>0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of result.LookupData.Territory.Items.Item"
                                                        [value]="item.Name"
                                                        [disabled]="selectedTerritories.value.length >= formField.multiSelectLimit && selectedTerritories.value.includes(item.Name) === false">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedTerritories.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formField.internalName === 'FIELD_TYPE_OF_INSURANCE'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedInsuranceTypes"
                                                    [(value)]="searchRequest.typeOfInsuranceString"
                                                    [multiple]="formField.multiSelectLimit>0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of result.LookupData.TypeOfInsurance.Items.Item" [value]="item.Name">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedInsuranceTypes.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formField.internalName === 'FIELD_MARKET'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedMarket"
                                                    [(value)]="searchRequest.marketString"
                                                    (selectionChange)="changeMarket()"
                                                    [multiple]="formField.multiSelectLimit>0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of result.LookupData.Market.Items.Item" [value]="item.Name">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedMarket.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formField.internalName === 'FIELD_CLASS_OF_BUSINESS'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedClassesOfBusiness"
                                                    [(value)]="searchRequest.classOfBusinesses"
                                                    [multiple]="formField.multiSelectLimit > 0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of filteredCOBList"
                                                        [value]="item.ClassOfBusiness"
                                                        [disabled]="selectedClassesOfBusiness && selectedClassesOfBusiness.value.length >= formField.multiSelectLimit && selectedClassesOfBusiness.value.includes(item.ClassOfBusiness) === false">
                                                {{item.ClassOfBusiness}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedClassesOfBusiness.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formField.internalName === 'FIELD_TAX_COUNTRY'">
                                <div>
                                    <mat-form-field>
                                        <mat-label>{{formField.displayName}}</mat-label>
                                        <mat-select [formControl]="selectedTaxCountries"
                                                    [(value)]="searchRequest.taxCountries"
                                                    [multiple]="formField.multiSelectLimit>0"
                                                    [required]="true"
                                                    [panelClass]="'mat-option-height'" disableOptionCentering>
                                            <mat-option *ngFor="let item of result.LookupData.TaxCountry.Items.Item"
                                                        [value]="item.Name"
                                                        [disabled]="selectedTaxCountries && selectedTaxCountries.value.length >= formField.multiSelectLimit && selectedTaxCountries.value.includes(item.Name) === false">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="selectedTaxCountries.hasError('required')">
                                            This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </div>
            <div class="col-lg-8 results-header">
                <div class="row search-parameters-header pb-2 pt-2">
                    <div class="col-lg-8 search-parameters-header">
                    </div>
                    <div class="col-lg-4 search-parameters-buttons">
                        <button mat-raised-button color="accent" (click)="resetSearch();" [disabled]="disableButton === true" class="mr-1">Reset</button>
                        <button mat-raised-button color="accent" (click)="searchResults();" [disabled]="(selectedClassesOfBusinessFieldIncluded && selectedClassesOfBusiness.hasError('required'))
                                 || (selectedTerritoriesFieldIncluded && selectedTerritories.hasError('required'))
                                 || (selectedTaxCountriesFieldIncluded && selectedTaxCountries.hasError('required'))
                                 || (selectedDocumentTypeFieldIncluded && selectedDocumentType.hasError('required'))
                                 || (selectedInsuranceTypesFieldIncluded && selectedInsuranceTypes.hasError('required'))
                                 || (selectedMarketFieldIncluded && selectedMarket.hasError('required'))
                                 || disableButton === true">
                            View Results
                            <mat-icon *ngIf="searchInProgress">
                                <mat-spinner diameter="20"></mat-spinner>
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--disclaimer-->
        <div *ngIf="result && result.LookupData && !searchComplete" class="row">
            <div class="col-lg-8 disclaimer">
                <p class="search-results-footer" *ngIf="contactDetails">
                    All QA Tool enquiries should be directed to the {{contactDetails.name? contactDetails.name : ""}} using the following email: <a href="mailto:{{contactDetails.email}}" class="home-contactinfo">{{contactDetails.email}}</a>.
                </p>
                <p class="search-results-footer">
                    For further assistance with global policies or trading advice, please contact Lloyd's International Trading Advice on <span style="white-space:nowrap;">+44 (0)207327 6677</span>, or email: <a href="mailto:LITA@lloyds.com" class="home-contactinfo">LITA@lloyds.com</a>.
                </p>
                <p class="search-results-footer">
                    Where a country does not appear under the Tax Country listing please use <a href="http://www.lloyds.com/The-Market/Tools-and-Resources/Tools-E-Services/Crystal" target="_blank" class="home-contactinfo">Crystal</a> to find out what taxes may be applicable.
                </p>
                <p class="search-results-footer mt-1">
                    The material in this document is provided for general information purposes only. Lloyd's does not warrant the accuracy and completeness of the material in this document. Lloyd's may make changes to the material in this document at any time without notice.
                </p>
            </div>
            <div class="col-lg-4">
                <div class="lcc-banner mb-1">
                    <a href="https://www.imagefast.co.uk/lcc" target="_blank">
                        <img [src]="lcc_logo" />
                    </a>
                </div>
            </div>
        </div>
        <!--Search Results-->
        <div *ngIf="searchComplete && groupedResults" class="row">
            <div class="col-lg-8">
                <div class="results-header p-3">
                    {{filteredRuleResult.count}} requirements to be checked for:
                    <div *ngIf="type=='OM'" class="wordwrap">
                        "{{searchRequest.typeOfInsuranceString}}, {{searchRequest.marketString}}, <ng-container *ngFor="let cob of searchRequest.classOfBusinesses, let i = index">{{cob}}<ng-container *ngIf="i < searchRequest.classOfBusinesses.length - 1">, </ng-container></ng-container>
                        <strong> International Requirements: </strong>
                        <ng-container *ngFor="let territory of searchRequest.territories, let i = index">{{territory}}<ng-container *ngIf="i < searchRequest.territories.length - 1">, </ng-container></ng-container>
                        <strong> Taxation: </strong>
                        <ng-container *ngFor="let tax of searchRequest.taxCountries, let i = index">{{tax}}<ng-container *ngIf="i < searchRequest.taxCountries.length - 1">, </ng-container></ng-container>"
                    </div>
                    <div *ngIf="type=='BA'" class="wordwrap">
                        "{{searchRequest.documentType}},
                        <strong>International Requirements: </strong>
                        <ng-container *ngFor="let territory of searchRequest.territories, let i = index">{{territory}}<ng-container *ngIf="i < searchRequest.territories.length - 1">, </ng-container></ng-container>"
                    </div>
                </div>
                <div class="results-header pl-3 pb-3">
                    <button mat-raised-button color="accent" (click)="expandAll();" class="mr-2">Expand All</button>
                    <button mat-raised-button color="accent" (click)="collapseAll();" class="mr-2">Collapse All</button>
                    <button mat-raised-button color="accent" (click)="amendSelection();" class="mr-2">Amend Selection</button>
                    <button mat-raised-button color="accent" (click)="generateExcel()" class="mr-2">
                        Export as Excel
                        <mat-icon *ngIf="exportToExcelInProgress">
                            <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                    <button mat-raised-button color="accent" (click)="generatePdf()">
                        Export as PDF
                        <mat-icon *ngIf="exportToPdfInProgress">
                            <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>
                <div *ngIf="!groupedResultKeys || filteredRuleResult.count == 0" class="p-3" flex>
                    <p>There are no results for your selected filters</p>
                </div>
                <div *ngIf="groupedResultKeys && filteredRuleResult.count > 0" flex>
                    <div *ngFor="let key of groupedResultKeys">
                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState=true" (closed)="panelOpenState=false" [expanded]="panelExpanded" class="results">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{key}} ({{groupedResults[key].length}})
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        <!--some descr-->
                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <table *ngIf="groupedResults[key]" id="rulesResult">
                                    <thead>
                                        <tr>
                                            <th class="reference">Reference</th>
                                            <th *ngIf="type=='OM'">Type</th>
                                            <th>Description</th>
                                            <th>Checked</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of groupedResults[key]">
                                            <td class="reference">
                                                {{item.reference}}
                                            </td>
                                            <td *ngIf="type=='OM'">
                                                {{item.classification}}
                                            </td>
                                            <td>
                                                <span *ngIf="item.description" [innerHtml]="item.description"></span>
                                            </td>
                                            <td class="text-center">
                                                <mat-checkbox [(ngModel)]="item.selected" (change)="select(item)"></mat-checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>
                </div>
                <div class="results-header mb-2 p-3">
                    <button mat-raised-button color="accent" (click)="expandAll();" class="mr-2">Expand All</button>
                    <button mat-raised-button color="accent" (click)="collapseAll();" class="mr-2">Collapse All</button>
                    <button mat-raised-button color="accent" (click)="amendSelection();" class="mr-2">Amend Selection</button>
                    <button mat-raised-button color="accent" (click)="generateExcel()" class="mr-2">
                        Export as Excel
                        <mat-icon *ngIf="exportToExcelInProgress">
                            <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                    <button mat-raised-button color="accent" (click)="generatePdf()">
                        Export as PDF
                        <mat-icon *ngIf="exportToPdfInProgress">
                            <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>
            </div>
            <!--side filter only displayed for Open Market-->
            <div class="col-lg-4">
                <div *ngIf="type=='OM'" flex="20">
                    <div class="filter">Filter By</div>
                    <div>
                        <div *ngFor="let item of classificationFilter"><mat-checkbox [(ngModel)]="item.selected" (change)="applyFilter()"> {{item.Description}}</mat-checkbox></div>
                    </div>
                </div>
                <!--<div *ngIf="type=='BA'" flex="20">
                    <div class="filter">Filter By</div>
                    <div>
                        <mat-radio-group aria-labelledby="radio-group-label" class="filter-radio-group" [(ngModel)]="chosenviewType">
                            <mat-radio-button class="radio-button" *ngFor="let view of viewType" [value]="view" (click)="applyBAFilter(view)">
                                {{view}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>-->
                <div *ngIf="type=='OM'" flex="20">
                    <div class="associated_links mt-2 mb-2">Associated Links</div>
                    <div>Associated Country Links</div>
                    <ng-container *ngFor="let territory of selectedTerritoriesList">
                        <div *ngIf="territory.CrystalLink">
                            <a href="{{territory.CrystalLink}}" target="_blank">For further information on {{territory.Name}}, click here</a>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTerritoriesHaveLinks === false">
                        <div>
                            <a target="_blank" href="http://www.lloyds.com/crystal/">For further information on international requirements, click here</a>
                        </div>
                    </ng-container>
                    <div *ngIf="selectedTaxCountriesHaveLinks === true">Associated Tax Links</div>
                    <ng-container *ngFor="let tax of selectedTaxCountriesList">
                        <div *ngIf="tax.CrystalLink">
                            <a href="{{tax.CrystalLink}}" target="_blank">For further information on {{tax.Name}}, click here</a>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="type=='BA'" class="linkNotetext" flex="20">
                    <a (click)="openNotes();">XIS Notes</a>
                </div>
            </div>
        </div>
        <!--Search results bottom text-->
        <div *ngIf="searchComplete && groupedResults" class="row">
            <div class="col-lg-8">
                <p class="search-results-footer">
                    The material in this document is provided for general information purposes only.
                </p>
                <p class="search-results-footer">
                    Lloyd's does not warrant the accuracy and completeness of the material in this document.
                </p>
                <p class="search-results-footer mb-3">
                    Lloyd's may make changes to the material in this document at any time without notice.
                </p>
            </div>
            <!--banner-->
            <div class="col-lg-4">
                <div class="lcc-banner mb-1">
                    <a href="https://www.imagefast.co.uk/lcc" target="_blank">
                        <img [src]="lcc_logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>