import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SearchRulesResponse, AdvancedRuleSearchRequest, LookupItem } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

    exportPdf(filteredRuleResult: SearchRulesResponse, searchRequest: AdvancedRuleSearchRequest, currentType: any) {

        const addFooters = doc => {
            const pageCount = doc.internal.getNumberOfPages()

            doc.setFont('helvetica', 'italic')
            doc.setFontSize(8)
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i)
                doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
                    align: 'center'
                })
            }
        }

        const doc = new jsPDF('p', 'mm', 'a4');
        doc.setFontSize(10);
        const title = currentType.Name + ' Checks';
        const d = new Date();
        const subTitle = 'Date : ' + d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
        const countString = filteredRuleResult.count + ' Standards to be checked for:';
        let searchCriteria: string;
        if (currentType.Code === 'BA') {
            searchCriteria = searchRequest.documentType + ' International Requirements: ' + searchRequest.territories;
        } else if (currentType.Code === 'OM') {
            searchCriteria = searchRequest.typeOfInsurance + ',' + searchRequest.market + ',' + searchRequest.classOfBusinesses + ' International Requirements: ' + searchRequest.territories + ' Taxation: ' + searchRequest.taxCountries;
        }
        const x = 15;
        let y = 15;
        const spacing = 5;
        doc.text(title, x, y);
        y = y + spacing;
        doc.text(subTitle, x, y);
        y = y + spacing;
        doc.text(countString, x, y);
        y = y + spacing;
        const splitSearchCriteria = doc.splitTextToSize(searchCriteria, 180);
        for (let i = 0; i < splitSearchCriteria.length; i++) {
            doc.text(splitSearchCriteria[i], x, y);
            y = y + spacing;
        }
        //doc.text(searchCriteria, x, y);
        //y = y + spacing;

        let tableHeaders;
        let colstyles = {};
        if (currentType.Code === 'BA') {
            tableHeaders = ['Reference', 'Checked', 'Description'];
            colstyles = {
                0: { cellWidth: 30 },
                1: { cellWidth: 18 }
            }
        } else if (currentType.Code === 'OM') {
            tableHeaders = ['Reference', 'Type', 'Checked', 'Description'];
            colstyles = {
                0: { cellWidth: 30 },
                1: { cellWidth: 12 },
                2: { cellWidth: 18 }
            }
        }

        const tableBody: any[] = [];
        filteredRuleResult.items.forEach(val => {
            const row: string[] = [];
            row.push(val.reference);
            if (currentType.Code !== 'BA') {
                row.push(val.classification);
            };
            row.push(val.selected ? 'Yes' : 'No');
            row.push(val.description);
            tableBody.push(row);
        });

        (doc as any).autoTable({
            startY: y,
            head: [tableHeaders],
            body: tableBody,
            columnStyles: colstyles,
        });

        addFooters(doc);

        doc.save(currentType.Name + ' Checks.pdf')
    }
}
