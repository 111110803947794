<div class="wrapper">
    <div class="container mt-3">
        <h1 id="pageTitle">QA Tool Frequently Asked Questions</h1>
        <p>New to the QA Tools? Here you'll find some useful information about how the tools work and answers to the most common questions.</p>

        <p *ngIf="!result">Loading ...</p>
        <!-- infinite scroll div -->
        <div *ngIf="result && result.items" class="search-results"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             (scrolled)="onScroll()">

            <div [ngClass]="result.items.length == result.totalRows? '':'overflow-height'" flex>
                <div *ngFor="let item of result.items" class="mb-2">
                    <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState=true" (closed)="panelOpenState=false" [expanded]="panelExpanded">
                            <mat-expansion-panel-header class="faq" expandedHeight="48px" collapsedHeight="48px">
                                <mat-panel-title class="faq">
                                    {{item.question}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <!--some descr-->
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <span *ngIf="item.answer" [innerHtml]="item.answer" class="mt-3" style="display:block;"></span>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="center">
                    <button mat-raised-button color="primary" *ngIf="result.items.length < result.totalRows" (click)="onScroll();">Show More</button>
                    <button mat-raised-button disabled *ngIf="result.items.length == result.totalRows">No More Items to Show</button>

                    <div *ngIf="result.items.length != result.totalRows" class="row pb-5">
                        <div class="col-md-12 p-5">
                            <ngx-spinner bdOpacity=0.9
                                         bdColor=""
                                         size="default"
                                         color="#581f87"
                                         type="ball-pulse-sync"
                                         [fullScreen]="false">
                            </ngx-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- infinity scroll div end -->
    </div>
</div>

